.button-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  width: 60%;
  padding: 0;
}

.button-container .btn {
  height: 80px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--text-pri);
  transition: 0.2s ease;
  overflow: hidden;
}

.button-container a {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.button-container .btn.pri {
  border: 2px solid var(--color-sec);
}

.button-container .btn.pri:hover {
  border: none;
  background-color: var(--color-sec);
}

.button-container .btn.pri::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 0%;
  left: 0;
  transition: 0.2s ease;
}
.button-container .btn.pri::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0%;
  left: 0;
  transition: 0.4s ease;
}
.button-container .btn.pri:hover::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 65%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.274);
}
.button-container .btn.pri:hover::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.089);
}

.button-container .btn.sec {
  background-color: var(--color-pri-light);
}

.button-container .btn.sec:hover {
  border: 2px solid var(--color-pri);
  background-color: transparent;
}

.button-container .btn.sec::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 65%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.171);
  transition: 0.2s ease;
}
.button-container .btn.sec::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.089);
  transition: 0.4s ease;
}

.button-container .btn.sec:hover::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 0%;
  left: 0;
}
.button-container .btn.sec:hover::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0%;
  left: 0;
}

@media (max-width: 820px) {
  .button-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    padding: 0;
    width: 100%;
  }

  .button-container .btn {
    height: 85px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--text-pri);
    transition: 0.2s ease;
    overflow: hidden;
    backdrop-filter: blur(20px);
  }
}

@media (max-width: 500px) {
  .button-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    padding: 0;
    width: 100%;
  }

  .button-container .btn {
    height: 75px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--text-pri);
    transition: 0.2s ease;
    overflow: hidden;
    backdrop-filter: blur(20px);
  }
}


@media (max-width: 400px) {
  .button-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    padding: 0;
    width: 100%;
  }

  .button-container .btn {
    height: 75px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--text-pri);
    transition: 0.2s ease;
    overflow: hidden;
    backdrop-filter: blur(20px);
  }
}
