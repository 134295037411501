.about-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  margin-top: 100px;
  padding: 50px;
  overflow-x: hidden;
  backdrop-filter: blur(20px);
}

@media (max-width: 820px) {
  .about-container {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: 100vh;
    margin-top: 100px;
    padding: 15px;
    overflow-x: hidden;
    backdrop-filter: blur(20px);
  }
}

@media (max-width: 500px) {
  .about-container {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: 100vh;
    margin-top: 100px;
    padding: 20px;
    overflow-x: hidden;
    backdrop-filter: blur(20px);
  }
}

@media (max-width: 400px) {
  .about-container {
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: 100vh;
    margin-top: 100px;
    padding: 30px 10px 0;
    overflow-x: hidden;
    backdrop-filter: blur(20px);
  }
}
